import clsx from 'clsx';
import React from 'react';
import Icon from '../../styles/atoms/icons';
import Link from '../../utils/Link';
import Img from '../../utils/OptimizedImage';

const logoThemes = {
  Blue: 'sm-light-logo',
  Cera: 'sm-dark-logo',
  Charcoal: 'sm-light-logo',
  Green: 'sm-light-logo',
  Light: 'sm-dark-logo',
  Midnight: 'sm-light-logo',
};

const HeaderNoMenu = ({
  background,
  disableLogoNavigation = false,
  isFullWidth = false,
  isHidden = false,
  logo,
}) => {
  const logoImage = logo ? (
    <Img
      image={logo.image.gatsbyImageData}
      src={logo.image.url}
      alt={logo.image.alt}
    />
  ) : (
    <Icon id={logoThemes[background || 'Light']} className="nav-logo" isImage />
  );

  return (
    <div
      className={clsx(
        'top-nav',
        isHidden && 'd-none',
        isFullWidth && 'full-width'
      )}
    >
      {disableLogoNavigation ? logoImage : <Link to="/">{logoImage}</Link>}
    </div>
  );
};

export default HeaderNoMenu;
